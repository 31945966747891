.routecard-main-wrapper {
  margin: 32px 16px;
}
.routecard-name-name {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  vertical-align: middle;
  margin: 0;
  padding-left: 16px;
  max-width: calc(100% - 54px);
  .mobile & {
    font-size: 22px;
    line-height: 24px;
  }
}
.routecard-name-image {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  width: 38px;
}
.routecard-name-under-name {
  margin-top: 8px;
  display: block;
  padding-left: 54px;
}
.routecard-name-rating {
  font-size: 13px;
  text-align: right;
  display: inline-block;
  background: url(../../../assets/imgs/multigo-stars.png) no-repeat scroll 0 0
    transparent;
  height: 14px;
  padding-left: 88px;
  color: #b3b3b3;
  vertical-align: middle;
  .mobile & {
    font-size: 15px;
  }
  line-height: 13px;
}

.routecard-main-link {
  cursor: pointer;
  line-height: 18px;
  float: right;
  height: 18px;
  vertical-align: middle;
  font-size: 13px;
  font-weight: 500;
  color: #4a90e2;
  background-color: white;
  border: 0px;
  .mobile & {
    font-size: 15px;
  }
}

.routecard-main-field {
  margin: 8px 0px;
  position: relative;
  width: 100%;
  svg {
    vertical-align: middle;
    display: inline-block;
  }
}

.routecard-main-field-text,
.routecard-main-field-link {
  width: calc(100% - 53px);
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  padding: 0px;
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  margin-left: 26px;
  .mobile & {
    font-size: 16px;
  }
}

.routecard-main-fields-wrapper {
  margin-top: 16px;
}
.routecard-main-icon {
  // position: absolute;
  // display: inline-block;
  // vertical-align: top;
  margin-left: 11px;
  path {
    fill: #999999;
  }
}
.routecard-main-field-link {
  color: #4a90e2;
  text-decoration: none;
}
.routecard-main-icon_withAccent {
  path {
    fill: #4a90e2;
  }
}
.routecard-name-subcat {
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  color: #333333;
  margin: 16px 0px;

  .mobile & {
    font-size: 18px;
  }
}
