.route-panel {
  width: 380px;
  height: 100%;
  position: absolute;
  left: 0px;
  margin: 0px;
  z-index: 3;
  box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.2);
  &.mobile {
    width: 100%;
  }
  // display: none;
}

.route-points-list {
  padding-left: 0px;
  position: relative;

  li {
    position: relative;
    list-style: none;
    margin-bottom: 8px;
    .md-list--nested-1 .md-list-tile {
      padding-left: 0px;
    }
    div {
      padding: 0px;
    }
    padding: 0px 16px;
  }

  .point {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    // top: 8px;
    top: 50%;
    transform: translate(0, -50%);
    left: 24px;
    color: #ffffff;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    z-index: 1;
  }
  .remove-point {
    border-radius: 50px;
    background-color: #f9f9f9;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    right: 30px;
    top: 50%;
    width: 16px;
    height: 16px;

    svg {
      opacity: 0.3;
      width: 16px;
      height: 16px;
    }
  }
  .remove-point:hover {
    svg {
      opacity: 0.8;
    }
  }

  .reverse-icon {
    position: absolute;
    top: 35px;
    left: 28px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 8px;
    background: white;
    svg {
      margin-left: 2px;
    }
    path {
      stroke: #808080;
    }
  }
  .green {
    background-color: #00b359;
  }
  .red {
    background-color: #d0021b;
  }
  .grey {
    background-color: #4a90e2;
  }
  .autocomplete__text {
    padding-left: 46px;
    padding-right: 30px;
    // width: calc(100% - 78px);
    font-weight: 500;
  }

  .autocomplete__text::placeholder {
    color: black;
  }
  .autocomplete__text:-moz-placeholder {
    color: black;
  }
  .dots {
    position: absolute;
    top: 7px;
    left: 6px;
    fill: #999999;
    display: none;
  }
  .dots:hover {
    display: inline;
  }
}
.route-points-list > li:hover {
  .dots {
    display: inline;
  }
}

.route-point-flag {
  position: absolute;
  top: 10px;
  left: 5px;
}
.route-point-row {
  border-radius: 5px;
  background: rgba(60, 60, 60, 0.1);
  padding-left: 2px;
  width: 100%;
  min-height: 45px;
  position: relative;
}
.route-point-name {
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 35px;
}

// .route-list-dragger {
//   position: absolute;
//   top: 11px;
//   left: 0px;
// }
.hover-icon {
  opacity: 0.3;
  display: inline-block !important;
}
.hover-icon:hover {
  opacity: 1;
}
.route-point-icons {
  width: 50px;
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  top: 13px;
  right: 0px;
}
#route-text-edit-menu {
  position: absolute !important;
  padding-right: 50px;
  top: 0;
  left: 35px;
}
.map-wrapper {
  z-index: 1;
}
.terminal-object-block {
  width: 200px;
  display: inline-table;
  border: 1px solid black;
  margin: 5px;
  padding: 3px;
  border-radius: 4px;

  .terminal-object-name {
    font-weight: 600;
  }
  // .terminal-object-address {
  // }
}
.terminal-object-block:hover {
  background: beige;
}

#objects-selector {
  min-height: 500px;
}

.route-settings-row {
  p {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #999999;
    margin-bottom: 4px;
    margin-top: 20px;
  }
  .md-cell {
    width: 100%;
    margin: 0;
  }

  margin: 16px;
  .md-text-field-container--input {
    width: 50px !important;
  }
  hr {
    border: 0;
    height: 1px;
    margin-top: 24px;
    background: #e6e6e6;
    width: 380px;
    left: -16px;
    position: relative;
  }

  .sets-value {
    padding: 4px 12px;
    color: #fff;
    font-size: 12px;
  }
}
.route-settings-row:first-of-type {
  margin-top: 24px;
}
.route-settings-name {
  font-weight: 700;
}
// .chippicker:first-child {
//   margin-top: 24px;
// }

.filter-chip-block {
  margin: 0px 16px 16px 16px;
  padding: 0px;
  hr {
    border: 0;
    height: 2px;
    margin-top: 24px;
    background: #e6e6e6;
    width: 375px;
    left: -16px;
    position: relative;
  }
}
.filter-chip-block:last-child {
  hr {
    visibility: hidden;
  }
}
.route-settings-header {
  background-color: #808080;
  // padding-top: 16px;
  width: 100%;

  // padding-bottom: 16px;
  display: inline-block;
  .route-settings-row {
    p {
      font-weight: 700;
      color: #f0f0f0;
    }
    .route-settings-name {
      margin-bottom: 8px;
    }
    margin-bottom: 24px;
  }
  select {
    background-color: white;
  }
}
.tabs-content {
  margin-top: 24px;
  height: calc(100% - 115px);
}

.route-buttons-panel {
  padding: 16px 16px 0px 16px;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);
  height: 44px;
  border-top: 1px solid rgba(167, 80, 80, 0.2);
  button {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.add-point {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4a90e2;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;

    path {
      stroke: #ffffff;
    }
  }
}
.route-build-status {
  margin: 16px 16px 0px 16px;
  padding-bottom: 16px;
  color: #999999;
  background-color: #def6ff;

  p {
    margin: 16px 0px 0px 0px;
    display: inline-block;
  }
  &__name {
    width: 158px;
    padding-left: 16px;
    color: #999999;
    font-size: 14px;
  }
  &__value {
    width: 158px;
    color: #333333;
    font-weight: 600;
    letter-spacing: 1.1px;
  }
}
.route-build-ctrl-run {
  margin: 16px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: stretch;
  align-items: flex-start;
}
.route-settings-datetime {
  position: relative;
  &__date {
    width: 282px;
    margin: 0px;
    height: 34px;
    // position: relative;
    // top: 3px;
    padding: 0px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px !important;
    background-color: #ffffff;
  }
  &__time {
    width: 64px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 15px;
    height: 32px;
    text-align: center;
    padding: 0px;
    position: absolute;
    right: 1px;
  }
  .react-calendar__tile--active:enabled {
    background-color: #00afec;
  }
  .react-calendar {
    font-family: "Montserrat", sans-serif;
  }
}

.route-filters {
  margin-bottom: 16px;
}
.edit-point-autocomplete {
  input {
    height: 38px;
  }
}
.progress-block {
  padding: 16px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 348px;
  color: #333333;
  text-align: center;
  &__percent {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  &__status {
    font-size: 14px;
    margin-top: 16px;
    color: #999999;
    margin-bottom: 32px;
  }
  &__status::first-letter {
    text-transform: capitalize;
  }
  &__error {
    p {
      color: red;
      font-size: 13px;
      margin: 8px;
    }
  }
  button {
    padding: 0px 16px;
  }
}

.vehicle-select {
  .autocomplete__text::placeholder {
    color: #333333;
  }
}
