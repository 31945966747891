.routecard-photo-photo {
  width: 100%;
  height: 214px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &.zoom {
    cursor: zoom-in;
  }
  min-height: 140px;
  background-image: url("../../../assets/imgs/multigo-no-photo-petrol.png");
  &_purpose {
    &_wash {
      background: url("../../../assets/imgs/multigo-no-photo-wash.png");
    }
    &_rest {
      background: url("../../../assets/imgs/multigo-no-photo-parking.png");
    }
    &_sleep {
      background: url("../../../assets/imgs/multigo-no-photo-hotel.png");
    }
    &_tire {
      background: url("../../../assets/imgs/multigo-no-photo-tire.png");
    }
  }
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 2px 4px 0 rgba(32, 11, 11, 0.5);
  }

  img {
    // width: 100%;
    position: relative;
    height: 214px;
    width: 100%;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
  }
}

.routecard-photo-panorama {
  position: relative;
}

.routecard-photo-buttons {
  position: absolute;
  width: 100%;
  bottom: 0px;
}
.routecard-photo-wrapper {
  position: relative;
  display: inline;
  width: 100%;
}
.routecard-photo-control {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  width: 50%;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0px;
  vertical-align: middle;
  text-transform: uppercase;
  .mobile & {
    font-size: 16px;
  }
}

.routecard-photo-control:first-of-type {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
