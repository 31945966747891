.float-box {
  position: fixed;
  z-index: 2200;
  top: 0;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(49, 52, 53, 0.5);
}
.float-box__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  // width: 420px;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  max-width: 650px;
  padding: 24px;
  background: #fff;
  -webkit-box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.2);
}
.float-box__head {
  position: relative;
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
.float-box__body {
  margin-top: 24px;
}
.float-box__close {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  float: right;
  color: #fff;
  background: #b3b3b3;
  border: 0;
  cursor: pointer;
  position: relative;
  text-align: center;
  &:before,
  &:after {
    position: absolute;
    left: 9px;
    top: 3px;
    content: " ";
    height: 14px;
    width: 2px;
    background-color: white;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
.float-box__close:hover {
  background: #00afec;
}
.float-box__title {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: #555;
}
