/* stylelint-disable */
.control-panel-wrapper {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  // float: left;
  // margin: 0 10px;
  width: 380px;
}

.control-panel-wrapper::-webkit-scrollbar {
  width: 5px;
}

.control-panel-wrapper::-webkit-scrollbar-thumb {
  background: #888888;
}
.control-panel-wrapper::-webkit-scrollbar-track {
  background: #ddd;
}
.ruble {
  &::after {
    margin-left: 4px;
    display: inline;
    text-decoration: none;
    content: "\20BD";
  }
}
.built-route-header {
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid #e1e4e8;
  height: 16px;
  padding: 16px;
  span {
    margin-left: 50px;
    line-height: 18px;
    font-size: 18px;
    font-weight: 400;
    color: #555555;
    background-color: transparent;
  }
}
.built-route-info-header {
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid #e1e4e8;
  padding: 16px;

  table {
    td {
      font-size: 15px;
      text-align: left;
      padding: 5px 12px;
      font-weight: bold;

      &:first-child {
        text-align: right;
        font-weight: normal;
      }
    }
  }
}
.buit-route-show-more {
  padding: 16px;
  .mobile & .checkbox__text {
    font-size: 18px;
  }
}
.close-route-button {
  top: 8px;
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  svg {
    position: absolute;
    top: 9px;
    left: 6px;
    path {
      stroke: #555555;
    }
  }
}
.close-route-button:hover {
  background-color: #00afec;
  path {
    stroke: white;
  }
}

// Css для карточек с предупреждениями
.attention .card-head {
  padding: 4px 0;
  box-sizing: border-box;
  position: relative;
}
.attention .warning-item-footer {
  color: #fff;
  position: relative;
  z-index: 99;
  padding: 0;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background-color: #ff001e;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.3px;
  text-align: center;
}
.title-attention {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 130px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  background-color: #ff001e;
  letter-spacing: 3px;
  border-radius: 12px;
}

/* Карточка на жёлтом фоне */
.mgo-attention-item-card {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  min-height: 60px;
  padding-left: 56px;
  padding-right: 12px;
  background-color: #ffe74c;
  box-sizing: border-box;
}
.mgo-attention-item-card__row {
  display: flex;
  flex: 1;
}
.mgo-attention-item-card__left,
.mgo-attention-item-card__right {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
.mgo-attention-item-card__left {
  flex: 1;
}
.mgo-attention-item-card__name {
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
.mgo-attention-item-card__name img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.mgo-attention-item-card__right {
  width: 72px;
}
.mgo-attention-item-card__km {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
}

.mgo-attention-item-card__near {
  width: 100%;
  margin-top: 7px;
  color: #333;
  font-size: 13px;
  font-weight: 400;
}
.mgo-attention__near-info {
  margin: 8px 0;
  padding: 3px 16px;
  background: #fff;
  border-radius: 4px;
}
.mgo-attention__near-row {
  margin-bottom: 5px;
}
.mgo-attention__near-row::after {
  display: block;
  content: "";
  clear: both;
}
.mgo-attention__near-name {
  padding-top: 8px;
  float: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
}
.mgo-attention__near-km {
  padding-top: 8px;

  float: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}
.mgo-attention__near-address {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.14;
  color: #808080;
}

/* Карточка на жёлтом фоне */
.route-list-item.attention.mobile {
  .mgo-attention-item-card__name {
    font-size: 16px;
  }
  .mgo-attention-item-card__km {
    font-size: 16px;
  }
  .mgo-attention-item-card__near {
    font-size: 15px;
  }
  .mgo-attention__near-address {
    font-size: 16px;
  }
}
