.routecard-fuel-colors-wrapper {
  margin-top: -1px;
  border: 1px solid #e5e5e5;
}
.routecard-fuel-colors-spectre {
  height: 24px;
  margin-left: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.5);
  .mobile & {
    line-height: 32px;
    height: 32px;
  }
}
.routecard-fuel-colors-spectre-block {
  height: 24px;
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  .mobile & {
    font-size: 17px;
  }
}
.routecard-fuel-colors-price-tag {
  height: 14px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333333;
  .mobile & {
    font-size: 16px;
  }
}

.routecard-fuel-colors-price-row {
  height: 24px;
  display: inline-block;
  width: calc(100% - 32px);
  margin: 0px 16px;
  margin-top: 16px;
}
.routecard-fuel-colors-price-tag {
  display: inline-block;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #808080;
}
.routecard-fuel-colors-fuel-tag {
  position: absolute;
  margin: 0;
  width: calc(100% - 32px);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  .mobile & {
    font-size: 18px;
  }
}
