.control-panel {
  height: 80px;
  width: 300px;
  position: absolute;
  top: 20px;
  right: 20px;
  // transform: translateX(-50%);
  z-index: 2;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.8);
}
