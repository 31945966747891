.route-card-wrapper {
  min-width: 365px;
}

// верстка мобильной версии
.route-card-wrapper.mobile {
  .mgo-accordion__head {
    font-size: 17px;
    font-weight: 700;
    line-height: 56px;
    .mgo-accordion__arrow {
      top: 25px;
      right: 21px;
    }
  }
}
