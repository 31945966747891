.mgo-punct-item-card {
  // Liigh item card
  height: 42px;
  // Punct block
  .mgo-punct-block {
    height: 100%;
    display: flex;
    display: -webkit-flex;
    .block-1,
    .block-2,
    .block-3 {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
    }
    .block-1 {
      flex: 1;
      overflow: hidden;
    }
    .block-2 {
      width: 54px;
      &.start-end {
        span {
          font-size: 12px;
          color: #000;
        }
      }
    }
    .block-3 {
      width: 74px;
    }
    .km,
    .road {
      margin-left: auto;
    }
  }
  &.mobile {
    height: 48px;
  }
}
