.routecard-services-wrapper {
  margin: 0;
  padding: 16px 8px 32px 8px;
}
.routecard-services-row {
  display: inline-block;
  width: calc(50% - 16px);
  position: relative;
  vertical-align: middle;
  margin: 8px;
  p {
    display: inline-block;
    width: 112px;
    margin: 0px 0px 0px 16px;
    position: relative;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    .mobile & {
      font-size: 16px;
    }
  }
  img {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.5);
    height: 32px;
    width: 32px;
  }
}
