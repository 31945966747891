.recommended-cards {
  font-size: 13px;
  font-weight: 400;
  color: #808080;
  .card-img {
    width: 60px;
    height: 40px;
    display: inline-block;
  }
  .card {
    display: inline-block;
    max-width: 80px;
    vertical-align: top;
    position: relative;
    margin: 8px;
  }
}
