.mgo-stars {
  font-size: 13px;
  text-align: right;
  display: inline-block;
  background: url(../../../../../../../../assets/imgs/multigo-stars.png)
    no-repeat scroll 0 0 transparent;
  height: 14px;
  padding-left: 88px;
  line-height: 12px;
  &.v1 {
    background-position: 0 0;
  }

  &.v2 {
    background-position: 0 -14px;
  }

  &.v3 {
    background-position: 0 -28px;
  }

  &.v4 {
    background-position: 0 -42px;
  }

  &.v5 {
    background-position: 0 -56px;
  }

  &.v6 {
    background-position: 0 -70px;
  }

  &.v7 {
    background-position: 0 -84px;
  }

  &.v8 {
    background-position: 0 -98px;
  }

  &.v9 {
    background-position: 0 -112px;
  }

  &.v10 {
    background-position: 0 -126px;
  }
}
