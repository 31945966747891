.save-send__item {
  background-color: rgba(0, 175, 236, 0.2);
  display: block;
  padding: 12px 16px;
  padding-right: 14px;

  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
  text-decoration: none;
  &:last-child {
    border: 0;
  }
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
.save-send__item:hover {
  background-color: rgba(0, 175, 236, 0.4);
}
.save-send__name {
  float: left;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: normal;
  color: #555555;
}
.save-send__img {
  float: right;
  width: 20px;
  height: 20px;
}
