.mgo-toll-road-block {
  display: flex;
  display: -webkit-flex;
  .block-1,
  .block-2,
  .block-3 {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }
  .block-1 {
    flex: 1;
    flex-direction: column;
    .name {
      height: 24px;
      font-size: 16px;
      color: #555;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 2px;
    }
    .desc {
      height: 18px;
      font-size: 13px;
      line-height: 18px;
      color: #808080;
      margin-bottom: 3px;
    }
    .desc:first-letter {
      text-transform: uppercase;
    }
  }
  .block-2 {
    width: 54px;
    flex-direction: column;
  }
  .block-3 {
    width: 64px;
    flex-direction: column;
  }
  .km,
  .road {
    margin-left: auto;
  }
}

.mobile .mgo-toll-road-block {
  .block-1 .name {
    font-size: 18px;
  }
  .time-year,
  .desc {
    font-size: 15px;
  }
  .time {
    font-size: 17px;
  }
  .block-1 .price {
    font-size: 16px;
    height: 24px;
    line-height: 24px;
  }
}
