.routecard-fuelcards-wrapper {
  padding: 16px 8px;
  //   text-align: center;
}
@import "../../RoutePanel/components/BuiltRoute/RouteList/components/items/css/tarif.scss";

.routecard-fuelcards-card {
  @include tarif-decorator;
  position: relative;
  display: inline-block;
  vertical-align: top;
  max-width: 130px;
  margin: 8px 16px;
  font-size: 14px;
  columns: #333333;

  .mobile & {
    font-size: 16px;
  }
  p {
    margin: 4px 0px;
  }
  .routecard-fuelcards-img {
    background-color: rgba(0, 0, 0, 0.1);
    width: 130px;
    height: 80px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.5);
    &_blured {
      opacity: 0.6;
    }
  }
  .routecard-fuelcards-tarif {
    height: 16px;
    font-size: 14px;
    font-weight: bold;
    .mobile & {
      font-size: 16px;
      height: 18px;
    }
  }

  .routecard-fuelcards-tarif_trand_negative {
    color: #ff0000;
  }
  .routecard-fuelcards-tarif_trand_positive {
    color: #1eb400;
  }
  .routecard-fuelcards-recommend {
    height: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #00afec;

    .mobile & {
      font-size: 16px;
    }
  }
}
