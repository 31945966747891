@mixin tarif-decorator {
  .percent-decorator {
    text-align: center;
    text-transform: none;
    color: white;
    position: absolute;
    border-radius: 50%;
    border: 1px solid white;
    z-index: 8;

    width: 14px;
    height: 14px;
    line-height: 14px;
    transform: translate(50%, 50%);
    font-size: 10px;
    bottom: 50%;
    right: 0;

    &.big {
      top: 10px;
      width: 26px;
      height: 26px;
      font-size: 18px;
      font-weight: 900;
      line-height: 26px;
      border: 2px solid white;
      letter-spacing: 1.29px;
    }

    &.negative {
      background-color: red;
    }
    &.positive {
      background-color: #00c300;
    }
    &.neutral {
      background-color: gray;
    }
    &.unknown {
      background-color: black;
    }
    .tooltip__wrapper {
      z-index: 10;
      white-space: normal;
    }
    .tooltip__text {
      z-index: 1000;
    }

    .tooltip__content {
      z-index: 0;
    }
  }
}
