@import "../../../components/RoutePanel/components/BuiltRoute/RouteList/components/items/css/tarif.scss";
.routecard-fuelprice-wrapper {
  padding: 16px 8px;
  // margin: 16px 0px;
  opacity: 0.9;
}
@mixin fuel-block {
  height: 32px;
  line-height: 32px;
  display: inline-block;
  margin: -1px 0 0 -1px;
  text-align: center;
  // opacity: 0.9;
  border: solid 1px #e6e6e6;
}

.routecard-fuelprice-block {
  width: calc(50% - 16px);
  display: inline-block;
  box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.5);
  vertical-align: top;
  margin: 8px;
}
.routecard-fuelprice-id,
.routecard-fuelprice-price {
  @include fuel-block;
  width: calc(50% - 1px);
  font-size: 16px;
  .mobile & {
    font-size: 18px;
  }
}
.routecard-fuelprice-name,
.routecard-fuelprice-date {
  width: calc(100% - 1px);
}

.routecard-fuelprice-price,
.routecard-fuelprice-name {
  // opacity: 0.9;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  .mobile & {
    font-size: 18px;
  }
}
.routecard-fuelprice-id {
  position: relative;
  @include tarif-decorator;
  font-size: 16px;
  font-weight: 700;
  color: white;
  // opacity: 0.63;
  background-color: #808080;
  &_active {
    background-color: #0095e6;
  }
  .mobile & {
    font-size: 16px;
  }
}
.routecard-fuelprice-price {
  background-color: #efeff4;
}
.routecard-fuelprice-date {
  // opacity: 0.9;
  font-size: 13px;
  color: #808080;
  @include fuel-block();
  .mobile & {
    font-size: 16px;
  }
}
