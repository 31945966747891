.routecard-navigation-wrapper {
  border: solid 1px #e5e5e5;
  background-color: #eff0f5;
  height: 82px;
  line-height: 82px;
  cursor: pointer;
  position: relative;
}
.routecard-navigation-button-panel {
  width: max-content;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.routecard-navigation-button {
  width: 116px;
  height: 42px;
  padding-top: 6px;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.5);
  background-color: #999999;
  margin: 0px auto;
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  color: white;
  line-height: 1.64;
  text-align: center;
  vertical-align: middle;
  .mobile & {
    font-size: 13px;
    height: 48px;
  }
  &_direction_prev {
    // border-top-left-radius: 95px;
    // border-bottom-left-radius: 95px;
    // padding-left: 16px;
    width: 100px;
    span {
      padding-left: 5px;
    }
  }
  &_direction_next {
    // border-top-right-radius: 95px;
    // border-bottom-right-radius: 95px;
    // padding-right: 16px;
    width: 100px;
  }

  &_direction_prev,
  &_direction_next {
    &:after {
      content: "";
      font-size: 0px;
      width: 0px;
      height: 0px;
      line-height: 0px;
      top: 0px;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      position: absolute;
    }
  }
  &_direction_prev:after {
    border-right: 12px solid #999999;
    left: -12px;
  }
  &_direction_next:after {
    border-left: 12px solid #999999;
    right: -12px;
  }
  .mobile &_direction_next,
  .mobile &_direction_prev {
    &:after {
      border-top-width: 27px;
      border-bottom-width: 27px;
    }
  }

  &_direction_current {
    background-color: #333333;
  }
}
.routecard-navigation-direction {
  margin-top: 6px;
}
.routecard-navigation-dist {
  line-height: 1;

  font-size: 16px;
  font-weight: bold;
  display: block;
  .mobile & {
    font-size: 18px;
  }
}
// routecard-navigation-button_direction_prev
.routecard-navigation-arrow {
  position: absolute;
  top: 14px;
  path {
    fill: white;
  }
  &_direction_prev {
    left: 16px;
  }
  &_direction_next {
    right: 16px;
    transform: rotate(180deg);
  }

  .mobile & {
    top: 17px;
  }
}
