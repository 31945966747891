.rto-block,
.rto-value {
  display: inline-block;
  line-height: 22px;
  vertical-align: middle;
}
.rto-value {
  margin: 0 0 0 8px;
  input {
    width: 32px;
    margin: 0px 8px;
  }
}

.rto-block,
.rto-selector-row {
  width: 165px;
  color: white;

  text-align: center;
  cursor: pointer;
  &-work {
    background-color: #00afec;
  }
  &-rest {
    background-color: #00b53a;
  }
  &-sleep {
    background-color: #f5a623;
  }
}
.rto-row {
  margin: 4px 0px;
  position: relative;
}

.rto-remover {
  position: relative;
  display: inline-block;
  line-height: 22px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  cursor: pointer;
  &:before,
  &:after {
    position: absolute;
    left: 7px;
    top: 1px;
    content: " ";
    height: 14px;
    width: 2px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &:hover {
    &:before,
    &:after {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
.rto-block-selector {
  z-index: 1;
  position: absolute;
  top: 0px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
}
.rto-selector-row {
  padding: 0px;
}
