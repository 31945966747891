.fuel-color-spectre-block {
  display: inline-block;
  height: 25px;
  cursor: pointer;
  input {
    cursor: pointer;
    display: block;
    margin: 0px auto;
    margin-top: 8px;
  }
}

.fuel-color-spectre-color-block {
  height: 20px;
  cursor: pointer;
  width: 100%;
}
.fuel-color-spectre-wrapper {
  padding: 16px;
  padding-bottom: 32px;
}
.fuel-color-spectre-text {
  font-size: 13px;
  color: #808080;
  margin: 10px 0px;
}
