.share {
  input[type="text"] {
    width: calc(100% - 20px);
    height: 40px;
    text-indent: 0;
    font-weight: 500;
    padding: 10px;
    color: #333;
    font-size: 16px;
    border: solid 1px #e1e1e1;
    background-color: #f9f9f9;
    margin-bottom: 16px;
  }
}

.share__label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #999;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.share__item {
  position: relative;
  height: 48px;
  line-height: 48px;

  font-size: 16px;
  font-weight: bold;
  color: #555;
}
.share__item-hovered {
  cursor: pointer;
  &:hover {
    color: #00afec;
  }
}
.ya-share2 {
  position: absolute;
  z-index: 100;
  top: 12px;
  right: 0;
}
