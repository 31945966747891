@import "./tarif.scss";
.mgo-common-item-card {
  color: #333;

  .mgo-address-block,
  .mgo-time-block,
  .mgo-fuel-cards-block,
  .mgo-services-block,
  .mgo-tank-block {
    display: flex;
    display: -webkit-flex;
  }

  .mgo-nofilter-mark {
    margin: 10px 0;
    .mgo-nofilter-mark-msg {
      color: red;
      border: 1px solid red;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
      padding: 8px 7px 7px;
    }
  }

  // Address block
  .mgo-address-block {
    height: 102px;
    .block-1,
    .block-2 {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
    }
    .block-1 {
      flex: 1;
      overflow: hidden;
    }
    .block-2 {
      width: 72px;
      min-width: 72px;
    }
    .name,
    .address,
    .rating {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .name {
      height: 24px;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 2px;
      img {
        float: left;
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
    .address {
      height: 18px;
      font-size: 13px;
      line-height: 18px;
      color: #808080;
      margin-bottom: 3px;
    }
    .rating {
      margin-top: 6px;
      height: 16px;
      font-size: 14px;
      line-height: 14px;
      color: #808080;
      b {
        color: #333333;
        text-align: left;
        font-weight: 500;
        margin-right: 8px;
      }
    }
    .km {
      flex: 1 1 auto;
      margin-top: 20px;
      margin-left: auto;
    }
    .mgo-go-button {
      margin-left: auto;
      flex: 0 1 auto;
    }
  }
  // Time block
  .mgo-time-block {
    height: 56px;
    .block-1,
    .block-2,
    .block-3 {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
    }
    .block-1,
    .block-3 {
      width: 96px;
      min-width: 96px;
    }
    .block-1 {
      text-align: left;
    }
    .block-2 {
      flex: 1;
      text-align: center;
    }
    .block-3 {
      text-align: right;
    }
    .date,
    .time,
    .label {
      height: 20px;
      line-height: 20px;
    }
    .time {
      position: relative;
      font-size: 15px;
      font-weight: 700;
      &.icon-in,
      &.icon-out {
        &:before {
          position: absolute;
          display: block;
          top: 50%;
          margin-top: -8px;
          width: 25px;
          height: 16px;
          content: "";
          background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAgCAYAAAD5VeO1AAAB50lEQVR4AWIgBRw5dUr74JET/QePHL964MixzyAMYoPEQHIM5ICrV6+yAdqpBu1KoiC4P7A2P3LtvW93Y9u2fRjbyVGMP3i2OrdOHgbJKEaPZ6qr5zZqbHI6f2xqJsQJ6agD34ABVh/x1PRQhCC8ubNLdoeDQqEQ4cA93uEbMMBqDoC/gdPM/GLYYrXRcYZvwAALH005xnL5ISJuaOuk8toG8ni9sgDAwke1BigU/gTLFlpWYSl9+MEop6hMFgBY+MBXkTzSFcgrCc3K/zAxIwcBKLe4nLxeX+wbsPCBb4zo4082ADCuwueU7HwUDmkAGa6KAYCNdJBDTv7D1C98Ts0uEJFX1DYSzO32UFZBCd5RQno28i0jN5QWp8tFGfnFIKakzFysQiEtOguaV1pBkZSBTKGgBlqxsr6JCsqqyOF0KbfihQ3R6Y//6QvX2UvurWZfsGZLTWlQPvw01UOLviQn31cfcWWTjjjIFyEH/JhDAGVxUjaZOP348f8tJ949lGzTLGO59+7AIu2Gwgn9ZfotfAYWoiXUfwTgxHuRFcwggCo59Fv6LCSP6j9jmY8+/mBLEfL9Lyz53amk5Vti4lOe99UI8TZWcYoFZVMR4vXvCQkvT7kVWQ/P9/hHlvbsVrMvQLMFdgA0CFG9hhwA3AAAAABJRU5ErkJggg==")
            no-repeat;
        }
      }
      &.icon-in {
        margin-left: 28px;
      }
      &.icon-in {
        &:before {
          left: -28px;
          // right: 0px;
          background-position: 0 0;
        }
      }
      &.icon-out {
        &:before {
          right: 44px;
          background-position: 0 -16px;
        }
      }
    }
    .date {
      font-size: 13px;
      color: #999;
    }
    .label {
      font-size: 13px;
      font-weight: 500;
      color: #999;
    }
  }
  // Fuelcards block
  .mgo-fuel-cards-block {
    // position: relative;
    z-index: 100;
    @include tarif-decorator;
    // .percent-decorator {
    //   transform: translate(50%, 50%);
    // }
    .recomend {
      color: #00afec;
      font-size: 11px;
      // padding-top: -16px;
      position: absolute;
      font-weight: 700;
      top: 12px;
    }
    flex-flow: row wrap;
    padding: 0 0 7px 0;
    .block {
      .inner {
        width: 40px;
        height: 26px;
        position: relative;
        display: inline-block;
        // &.blured {
        //   img {
        //     opacity: 0.4;
        //   }
        // }
        box-shadow: 0 2px 4px 0 rgba(32, 11, 11, 0.5);
      }

      // width: 128px;
      height: 32px;
      margin-top: 7px;
      margin-right: 16px;
      font-size: 13px;
      line-height: 25px;
      font-weight: 500;
      white-space: nowrap;

      .text {
        vertical-align: top;
        height: 32px;
        line-height: 28px;
        margin-left: 10px;
        position: relative;
        display: inline-block;
        text-overflow: ellipsis;
        // overflow: hidden;
      }
    }
    img {
      // float: ;
      width: 40px;
      height: 26px;
      margin-right: 16px;
    }
  }
  // Services block
  .mgo-services-block {
    flex-flow: row wrap;
    padding: 0 0 7px 0;
    img {
      width: 28px;
      height: 28px;
      margin-top: 7px;
      margin-right: 8px;
    }
  }
  // Tank block
  .mgo-tank-block {
    padding-top: 12px;
    padding-bottom: 17px;
    .block-1,
    .block-2 {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
    .row {
      height: 24px;
    }
    .label,
    .value {
      line-height: 24px;
    }
    .label {
      font-size: 13px;
      color: #999;
      margin-right: 14px;
    }
    .value {
      font-size: 14px;
      font-weight: bold;
    }
    .fuel-price {
      @include tarif-decorator;
      width: 132px;
      height: 100%;
      font-size: 14px;

      .name,
      .value {
        position: relative;
        width: 50%;
        height: 100%;
        float: left;
        font-weight: bold;
        box-sizing: border-box;
        vertical-align: middle;
      }
      .name {
        color: #fff;
        line-height: 24px;
        text-align: center;

        text-transform: uppercase;
        background-color: #999999;
      }
      .value {
        line-height: 22px;
        text-align: center;
        // border-style: solid;
        // border-color: #999999;
        // border-width: 1px 1px 1px 0;
      }
    }
  }

  .item-card-row {
    border-bottom: 1px solid #e6e6e6;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.mgo-trassa-name {
  font-size: 12px;
  min-width: 32px;
  height: 20px;
  color: white;
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  box-sizing: border-box;
  -moz-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: inline-block;
  font-weight: 500;
  padding: 1px;
  background-color: #4a90e2;
  .inner {
    padding: 0 6px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -moz-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: 1px solid white;
    outline-offset: -2px;
  }
}

.mgo-pin {
  position: absolute;
  display: block;
  height: 24px;
  width: 24px;
  top: 4.5px;
  left: 5px;
  line-height: 25px;
  border-radius: 100%;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  background-color: #4a90e2;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
}
