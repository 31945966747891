.routecard-fuel-tank-wrapper {
  height: 173px;
  margin-bottom: 8px;
}
.routecard-fuel-tank-capacity {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 9px;
  text-align: center;
  &-name {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
  }
  .mobile &-name {
    font-size: 16px;
  }
  &-value {
    margin-left: 16px;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #333333;
  }
  .mobile &-value {
    font-size: 18px;
  }
}
.routecard-fuel-tank-volume {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 16px;
  margin-right: 16px;
  display: block;
  background-color: #d7f8ff;
  box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.5);
}

.routecard-fuel-tank-section {
  display: inline-block;
  vertical-align: top;

  height: 48px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  .mobile & {
    height: 56px;
    font-size: 18px;
  }
}
.routecard-fuel-tank-section-name,
.routecard-fuel-tank-section-value {
  margin: 0px;
  padding: 0px;
}
.routecard-fuel-tank-section-value {
  margin-top: -3px;
}
.routecard-fuel-tank-section-name {
  font-size: 13px;
  padding-top: 6px;
  .mobile & {
    font-size: 15px;
  }
}
.routecard-fuel-tank-section_type {
  &_before {
    color: white;
    background-color: #4a90e2;
    min-width: 60px;
  }
  &_filled {
    min-width: 60px;
    color: #333333;
    background-color: #8be1ff;
  }
  // &_empty {
  //   background-color: #d7f8ff;
  // }
}
.routecard-fuel-tank-info {
  height: 52px;
  width: calc(50% - 12px);

  position: relative;
  display: inline-block;
  // background-color: #f2f2f2;
  border-radius: 4px;
  border: solid 1px #bbbbbb;
  &-wrapper {
    margin: 20px 16px;
  }
  .name {
    top: 0%;
    transform: translate(-50%, -50%);
    left: 50%;
    white-space: nowrap;
    position: absolute;
    background: white;
    padding: 0px 8px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    text-align: center;
    color: #999999;
    .mobile & {
      font-size: 15px;
    }
  }
  .value {
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    .mobile & {
      font-size: 17px;
    }
  }
}
.routecard-fuel-tank-info:last-of-type {
  float: right;
}
