/* stylelint-disable */
.mgo-left {
  float: left;
}
.mgo-right {
  float: right;
}
.mgo-cls {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.route-list {
  position: relative;
  border-bottom: 9px dashed rgba(0, 0, 0, 0.09);
  .line {
    position: absolute;
    z-index: 100;
    top: 20px;
    bottom: 20px;
    left: 30px;
    height: auto;
    width: 4px;
    background-color: #b2b2b2;
  }

  // Рекомендованные POI
  &.recom {
    .t-32 {
      .mgo-common-item-card {
        position: relative;
        &::before {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          z-index: 102;
          content: "";
          background-color: rgba(255, 255, 255, 0.7);
        }
      }
      &.active {
        .mgo-common-item-card {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
.route-list-item {
  position: relative;
  cursor: pointer;
  height: auto;
  background-color: white;
  // border-bottom: 2px solid rgba(0, 0, 0, 0.09);
  .mgo-pin {
    top: -2px;
    left: -2px;
    width: 24px;
    height: 24px;
    line-height: 25px;
    position: absolute;
    display: block;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    background-color: #4a90e2;
    border: 2px solid white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  }
  border-bottom: 2px solid rgba(0, 0, 0, 0.09);

  &.punct,
  &.city,
  &.landmark {
    background-color: #f4f5f7;
  }
  &.river {
    color: #888888;
  }
  .point,
  .brand {
    float: left;
  }
  .point {
    position: absolute;
    top: 50%;
    z-index: 400;
  }
  &.punct {
    .point {
      left: 20px;
      margin-top: -12px;
    }
    .name {
      color: #333;
      letter-spacing: -0.9px;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  &.city {
    .name {
      color: #555;
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .point {
      left: 16px;
      margin-top: -16px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  &.landmark {
    font-size: 14px;
    columns: #555;
    .point {
      left: 20px;
      margin-top: -12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  /* Заголовок карточки */
  /* Рекомендованные POI по категориям */
  // &.poi-98,
  // &.poi-32,
  // &.poi-23 {
  //   &::after {
  //     position: absolute;
  //     width: 4px;
  //     top: 0;
  //     bottom: 0;
  //     content: "";
  //   }
  // }
  &.poi-98 {
    .mgo-common-item-card {
      border-bottom: 4px solid #436f85;
      border-right: 4px solid #436f85;
      border-left: 4px solid #436f85;
    }
    &::after {
      background-color: #436f85;
    }
    .card-head {
      color: #fff;
      background-color: #436f85;
    }
    &.reserve {
      .card-head {
        color: #436f85;
        background-color: #b2cedc;
      }
      &::after {
        background-color: #b2cedc;
      }
      .mgo-common-item-card {
        border-bottom: 4px solid #b2cedc;
        border-right: 4px solid #b2cedc;
        border-left: 4px solid #b2cedc;
      }
    }
  }
  &.poi-32 {
    .mgo-common-item-card {
      border-bottom: 4px solid #f5a623;
      border-right: 4px solid #f5a623;
      border-left: 4px solid #f5a623;
    }
    &::after {
      background-color: #f5a623;
    }
    .card-head {
      color: #fff;
      background-color: #f5a623;
    }
    &.reserve {
      &::after {
        background-color: #ffe0b7;
      }
      .card-head {
        color: #f5a623;
        background-color: #ffe0b7;
      }
      .mgo-common-item-card {
        border-bottom: 4px solid #ffe0b7;
        border-right: 4px solid #ffe0b7;
      }
    }
  }
  &.poi-23 {
    .mgo-common-item-card {
      border-bottom: 4px solid#00b53a;
      border-right: 4px solid#00b53a;
    }
    &::after {
      background-color: #00b53a;
    }
    .card-head {
      color: #fff;
      background-color: #00b53a;
    }
    &.reserve {
      .card-head {
        color: #00b53a;
        background-color: #c3eab4;
      }
      &::after {
        background-color: #c3eab4;
      }
      .mgo-common-item-card {
        border-bottom: 4px solid#c3eab4;
        border-right: 4px solid#c3eab4;
      }
    }
  }

  &.shift {
    padding: 0;
    /* POI on the left, on the right side of road */
    &.left-side,
    &.right-side {
      &::before {
        position: absolute;
        top: 50%;
        margin: -4px 0 0 0;
        display: block;
        height: 0;
        width: 0;
        border-style: solid;
        border-width: 4px;
        color: #b2b2b2;
        content: "";
      }
      &.toll {
        &::before {
          z-index: 400;
          color: #bd10df;
        }
      }
      &.platon {
        &::before {
          z-index: 300;
          color: #4959ff;
        }
      }
    }
    &.right-side {
      &::before {
        left: 24px;
      }
    }
    &.left-side {
      &::before {
        left: 32px;
      }
    }
  }
  &:first-child {
    .mgo-pin {
      background-color: #00b359;
    }
  }
  &:last-child {
    .mgo-pin {
      background-color: #d0021b;
    }
  }
  .km,
  .road {
    float: right;
  }
  .km {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
  }
  .road {
    font-weight: bold;
    text-align: center;
    .mgo-trassa-name {
      font-size: 11px;
      min-width: 32px;
      height: 20px;
      color: white;
      text-align: center;
      line-height: 20px;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      box-sizing: border-box;
      -moz-sizing: border-box;
      -webkit-box-sizing: border-box;
      display: inline-block;
      font-weight: 500;
      padding: 1px;
      background-color: #4a90e2;
      .inner {
        padding: 0 6px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        -moz-sizing: border-box;
        -webkit-box-sizing: border-box;
        outline: 1px solid white;
        outline-offset: -2px;
      }
    }
  }
  .mgo-punct-block {
    background-color: #ebebeb;
  }
  .card-head {
    height: 32px;
    font-size: 15px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .mgo-punct-block,
  .mgo-punct-item-card,
  .mgo-common-item-card {
    position: relative;
  }
  .mgo-punct-block,
  .mgo-common-item-card {
    padding-left: 56px;
    padding-right: 12px;
  }
  .mgo-common-item-card {
    padding-bottom: 14px;
  }

  .mgo-fuel-price {
    height: 20px;
    line-height: 18px;
    text-align: right;
    width: 100px;
    margin-top: 2px;
    margin-right: 10px;
    display: inline-block;
    &:last-child {
      margin-right: 0;
    }
  }
  .price,
  .volueme-fuel-price {
    height: 20px;
    width: 80px;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    text-align: center;
    margin-top: 2px;
    background-color: rgba(0, 0, 0, 0.2);
    .value {
      color: #000;
    }
  }
  .group-price {
    .mgo-fuel-price,
    .volueme-fuel-price {
      float: left;
    }
    .volueme-fuel-price {
      color: white;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  .item-footer {
    display: none;
    .time-year {
      padding-left: 24px;
      .time,
      .year {
        color: white;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        content: "";
        opacity: 0.5;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAMtJREFUOBGlU0sOgjAUrCxYeRJJOAYncVFO44akPQuJlzDBi+BW60xDaQUxfTjJtO87vIZWqQWccxV4AQdwnEibsWpRHl0kS7ADn+AWmGNNGTthMQBewVywNorAoeoKxhhHbqDjFAWSPFdLR4iWvQWaziB3KXwvl0bamdQ3B4wxInBMgrNprZ3tYGitg8n9sWf0VEBxggGR00c037lzgj6/flXZcwL+xhsoPc4LPbWXhMjXi4T4L/iLFAT+u8pUwaf2PyY/xrRASPSc3xywhe1pqN9RAAAAAElFTkSuQmCC")
          no-repeat;
      }
    }
  }
  &.toll,
  &.platon {
    .mgo-toll-road-block {
      padding: 10px 12px 10px 56px;
    }
    .line {
      top: -2px;
      bottom: 0;
      left: 30px;
      width: 4px;
      height: calc(100% + 3px);
      &.start,
      &.finish {
        &::before {
          position: absolute;
          top: 0;
          left: -5px;
          width: 14px;
          height: 4px;
          content: "";
        }
      }
      &.start {
        top: 50%;
      }
      &.finish {
        bottom: 50%;
        height: auto;
        &::before {
          top: 100%;
        }
      }
    }
  }
  &.toll {
    .line {
      z-index: 300;
      background-color: #bd10df;
      &.start,
      &.finish {
        &::before {
          background-color: #bd10df;
        }
      }
    }
    .mgo-toll-road-block {
      background-color: rgba(250, 220, 255, 1);
    }
    .price {
      width: 80px;
      height: 20px;
      font-size: 14px;
      background-color: #bd10df;
      color: white;
    }
  }
  &.platon {
    .line {
      z-index: 200;
      background-color: #4959ff;
      &.start,
      &.finish {
        &::before {
          background-color: #4959ff;
        }
      }
    }
    .mgo-toll-road-block {
      background-color: rgba(220, 225, 255, 1);
    }
    .price {
      width: 80px;
      height: 20px;
      font-size: 14px;
      background-color: #4959ff;
      color: white;
    }
  }

  &:hover {
    background-color: rgba(220, 250, 250, 1);
    .price,
    .mgo-fuel-price {
      .value {
        background-color: white;
      }
    }
  }
  &.active {
    background-color: rgba(220, 250, 250, 1);
  }
  &.with-footer {
    margin-top: -2px;
    // margin-bottom: -2px;
    border-top: 3px solid #555;
    &.left-side,
    &.right-side {
      &::before {
        margin-top: -23px;
      }
    }
    .price,
    .mgo-fuel-price {
      .value {
        background-color: white;
      }
    }
    &.toll,
    &.platon {
      .line {
        &.start {
          top: calc(50% - 15px);
        }
        &.finish {
          bottom: calc(50% + 15px);
        }
      }
    }
    .item-footer {
      position: relative;
      z-index: 99;
      height: 32px;
      min-height: 3px;
      display: block;
      padding: 0 12px 0 56px;
      background-color: #555;
      .time-year {
        color: white;
        font-size: 14px;
        line-height: 32px;
      }
      a {
        color: #88f2ff;
        font-size: 13px;
        line-height: 32px;
        font-weight: 500;
        text-decoration: none;
        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .time-year {
    position: relative;
    font-size: 13px;
    // padding-left: 26px;
    .time {
      margin-right: 10px;
      font-weight: bold;
    }
  }

  //мобильная верстка размера щрифта
  &.mobile {
    &.punct .name {
      font-size: 18px;
    }
    .block-2,
    .block-3 {
      .km {
        font-size: 16px;
      }
    }
    .mgo-punct-block {
      .block-1 .name {
        font-size: 18px;
      }
      .mgo-trassa-name {
        font-size: 13px;
      }
    }

    .card-head {
      font-size: 18px;
      height: 48px;
      line-height: 48px;
    }
    .mgo-common-item-card .mgo-address-block {
      .name {
        font-size: 20px;
      }
      .address {
        font-size: 15px;
      }
    }
    .mgo-time-block {
      .time {
        font-size: 17px;
      }
      .date,
      .label {
        font-size: 15px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .mgo-fuel-cards-block {
      .text {
        font-size: 15px;
      }
    }
    .mgo-tank-block {
      .value,
      .name {
        font-size: 16px;
        line-height: 24px;
      }

      .label {
        font-size: 15px;
      }
    }

    .item-footer {
      height: 48px;
      .time,
      .year,
      .mgo-right {
        font-size: 18px;
        line-height: 48px;
      }
    }
  }
}
