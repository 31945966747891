.route-card-schedule-wrapper {
  height: 79px;
}
.route-card-schedule-inner {
  margin: 16px;
  // display: inline;
  position: absolute;
  width: calc(100% - 32px);
  margin-bottom: 24px;
}

.route-card-schedule-time-block {
  display: inline;
  div {
    text-align: center;
  }
  .date {
    height: 16px;
    opacity: 0.9;
    font-size: 14px;
    color: #808080;
    margin-top: 3px;
    .mobile & {
      font-size: 16px;
    }
  }
  .time {
    svg {
      top: 2px;
      position: relative;
      margin-right: 10px;
    }
    display: inline;
    opacity: 0.9;
    font-size: 15px;
    font-weight: 700;
    color: #333333;
    .mobile & {
      font-size: 17px;
    }
  }
}
.route-card-schedule-stop {
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  div {
    text-align: center;
  }
  &-time {
    height: 20px;
    opacity: 0.9;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    margin-top: 3px;
    color: #333333;
    .mobile & {
      font-size: 17px;
    }
  }
  &-name {
    opacity: 0.9;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    width: 116px;
    height: 20px;
    opacity: 0.9;
    color: #808080;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    .mobile & {
      font-size: 16px;
      height: 22px;
    }
  }
}

.route-card-schedule-stop-name_purpose_ {
  &sleep {
    color: #ffffff;
    background-color: rgb(239, 176, 39);
  }
  &rest {
    color: #ffffff;
    background-color: #00b53a;
  }
  &refuel {
    color: white;
    background-color: #436f85;
  }
}
