.routecard-navigation-string {
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 175, 236, 0.2);
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 48px;
  padding-left: 16px;
  cursor: pointer;
  position: relative;
  .mobile & {
    font-size: 18px;
  }
}
.routecard-navigation-copy {
  height: 16px;
  z-index: 500;
  position: absolute;
  right: 20px;
  top: 16px;
}

.routecard-navigation-copy-done {
  height: 16px;
  z-index: 500;
  position: absolute;
  right: 20px;
  top: 16px;
  fill: green;
}

.routecard-navigation-string:hover {
  background-color: rgba(0, 175, 236, 0.3);
}
.routecard-navigation-link {
  text-decoration: none;
}
.routecard-navigation-arrow-right {
  z-index: 500;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: scale(-1, -1) translateY(50%);
  //   transform:
}
