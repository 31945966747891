.routecard-trassa-wrapper {
  padding: 16px;
  margin: 0;
}
.routecard-trassa-key,
.routecard-trassa-value {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0px;
}
.routecard-trassa-trassa {
  //   position: absolute;
  display: inline-block;
  vertical-align: baseline;
  margin-left: 8px;
  font-size: 12px;
  min-width: 32px;
  height: 20px;
  color: white;
  text-align: center;

  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  box-sizing: border-box;
  -moz-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: inline-block;
  font-weight: 500;
  padding: 1px;
  background-color: #4a90e2;
  .mobile & {
    height: 24px;
  }
  .inner {
    line-height: 18px;
    padding: 0 8px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -moz-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid white;
    // outline: 1px solid white;
    // outline-offset: -2px;
    .mobile & {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.routecard-trassa-value {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  line-height: 18px;
  .mobile & {
    font-size: 16px;
  }
}
.routecard-trassa-key {
  font-size: 14px;
  color: #808080;
  text-transform: uppercase;
  .mobile & {
    font-size: 16px;
  }
}
