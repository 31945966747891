// @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,700i&display=swap&subset=cyrillic");
@import "./RoutePanel.scss";
@import "./ControlPanel.scss";
// @font-face {
//   font-family: "Montserrat", sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   // src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2)
//   //   format("woff2");
// }
@import "./mobile.scss";
body {
  margin: 0px;
  font-family: "Montserrat", sans-serif;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.map-component-wrapper {
  position: absolute;
  left: 380px;
  width: calc(100% - 380px);
  height: 100%;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  // z-index: 1;
}

.show-vechicle-editor {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 5;
}

.edit-vehicle-button {
  position: absolute;
  top: 22px;

  right: 14px;
  border: 0px;
  background-color: #f0f0f0;
  color: #8ae1ff;
  cursor: pointer;
  background: transparent;
}
.edit-vehicle-button:hover {
  color: #00afec;
  font-weight: 700;
}
.init-map-error {
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    color: red;
    font-weight: 700;
  }
}
.scroll-content {
  height: 100%;
  overflow-y: auto;
}

.scroll-content::-webkit-scrollbar {
  width: 5px;
}

.scroll-content::-webkit-scrollbar-thumb {
  background: #888888;
}

.scroll-content::-webkit-scrollbar-track {
  background: #ddd;
}
