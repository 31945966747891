.external-route__select {
  width: 100%;
  height: 40px;
  outline: none;
  padding: 10px 5px;
  overflow: hidden;
  text-indent: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAUlJREFUOI1jYBj6ID23UotcvVkFNdpMjIxMpZm51W4ka86r8WL4w1DK9O/H6zRGBob47PxqB+I11zoz/P8f/efXq1Tms2fP/rMwN9j67z9Hu4m57dMzpw4/xqc5I6fWlpHhf9ZrIZb4xX19v5kZGBgYTp069dfG2njLn79sveamNndPnz78DKvN+bXmDP//l3z7zByzbGLjTwYGBgZmmOSJEyf+mJk4bmVg/jfR3Mz++qlTh14ia84uqDJi+Pe/lov9R8z06e3fYeLMyIpOnz74y9DAajsDM+M0Y3ObC2dPHXnNwMDAkJFXqcP4n7n1B8ev6Gk9PV+Q9TBic2pyYb0Q+9+/S/4yMOSz/vvL/JeBsZeNmTV64sTGD+hqsRrAwMDAkJtbKfr7P8MaRkbmf0z//oRMndrxFpdanCAtp14jIa9GlWSNQwsAAP31erIxkEGJAAAAAElFTkSuQmCC)
    #f9f9f9 no-repeat 97%;
  -moz-appearance: none;
  -moz-border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 16px;
}
.external-route {
  .select-field {
    width: calc(100%);
    margin-bottom: 16px;
  }
}
