.short-route-link {
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }
  p {
    margin: 15px 0 20px 0;
    line-height: 20px;
  }
  form {
    margin-top: 10px;
    padding: 0 !important;
  }
  .important-field {
    font-weight: 500;
    font-size: 14px;
    color: #d0021b;
  }
  .share-link {
    .ya-share {
      float: right;
    }
    .label {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
    }
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 29px;
    background-color: #fff;
  }
}
.short-route-link__item {
  padding-left: 20px;
  margin-bottom: 20px;
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #999;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  input,
  select,
  textarea {
    width: calc(100% - 32px);
    display: block;
    padding: 0px 16px;
    text-indent: 0;
    color: #333;
    font-size: 16px;
    border: solid 1px #e1e1e1;
    background-color: #f9f9f9;
  }
  input[type="text"] {
    height: 40px;
  }
  textarea {
    height: 100px;
    padding-top: 8px;
  }
  select {
    width: 100%;
    height: 40px;
    font-size: 18px;
  }
}
.short-route-link__info {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}
.short-route-link__info,
.short-route-link__button-place {
  text-align: right;
}
