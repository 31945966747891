/* stylelint-disable */

.route-info {
  border: 3px solid #00afec;
  /* Пункты маршрута */
  .route-puncts {
    padding: 16px;
    .pin {
      // top: 50%;
      // margin-top: -13px;

      left: 0;
      display: block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      position: absolute;
      background-color: #4a90e2;
      border: 2px solid white;
      color: white;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      border: 2px solid white;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    }
    .wrapper {
      position: relative;
      &::before {
        top: 0px;
        bottom: 50px;
        left: 12px;
        position: absolute;
        content: "";
        border: 2px dotted #b2b2b2;
      }
    }
    .route-puncts__item {
      position: relative;
      min-height: 25px;
      padding-left: 40px;
      margin-bottom: 10px;
      display: flex;
      &:first-child {
        .pin {
          background-color: #00b359;
        }
      }
      &:last-child {
        margin-bottom: 0;
        .pin {
          background-color: #d0021b;
        }
      }
    }

    .route-puncts__content {
      display: inline-flexbox;
      justify-content: center;
      align-items: center;
    }
    .address {
      width: 302px;
    }
    .address,
    .time-year {
      color: #333;
      font-size: 15px;

      margin: 0px;
    }
    .time-year {
      line-height: 22px;
      margin-left: 8px;
      display: inline-block;
    }
    svg {
      position: relative;
      opacity: 0.4;
      top: 2px;
      width: 16px;
      height: 16px;
      circle {
        fill: rgba(0, 0, 0, 0.2);
      }
    }
    .time,
    .address {
      font-weight: bold;
    }
    .time {
      margin-right: 10px;
    }
    .address {
      line-height: 18px;
    }
  }
  .route-settings {
    &.route-info__group {
      padding-right: 0px;
    }
    .col_1 {
      flex: 1.6;
    }
    .col_1,
    .col_2,
    .col_3 {
      line-height: 16px;
    }
    .route-info__item {
      padding-top: 4px;
      padding-bottom: 4px;
      line-height: 16px;
    }
  }
  .route-info__item {
    &-rt0:last-of-type {
      margin-bottom: 10px;
    }
    &.title {
      font-size: 18px;
      color: #333333;
      text-transform: uppercase;
      font-weight: 600;
    }
    &.subtitle {
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      font-weight: 600;
      font-size: 16px;
      color: #333333;
      margin-right: 16px;
      margin-top: 24px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 8px;
    }
    // &.subtitle:first-of-type {
    //   border-top: 0px;
    //   margin-top: 0px;
    // }

    &.error {
      background-color: rgba(255, 0, 0, 0.2);
    }
    display: flex;
    display: -webkit-flex;
    line-height: 24px;
    .col_1,
    .col_2,
    .col_3 {
      display: flex;
      display: -webkit-flex;
      flex-flow: column wrap;
      //justify-content: center;
    }
    .col_1 {
      flex: 1.1;
    }
    .col_2 {
      flex: 1;
    }
    .col_3 {
      width: 32px;
      .label {
        cursor: help;
        align-self: flex-end;
        width: 18px;
        border: 1px solid white;
        height: 18px;
        line-height: 20px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        text-transform: uppercase;
        font-size: 11px;
        text-align: center;
        color: #fff;
        overflow: hidden;
        font-weight: 700;

        &.type-3 {
          background-color: #22cc00;
        }
        &.type-2 {
          background-color: #ff9900;
        }
        &.type-1 {
          background-color: #aebb00;
        }
        &.type-0 {
          background-color: red;
        }
      }
    }
    .col_1 {
      color: #999;
      font-size: 13px;
      font-weight: 500;
      margin-right: 5px;
    }
    .col_2 {
      font-size: 13px;
      font-weight: bold;
      color: #333;
      padding-right: 4px;
    }
    .total-price,
    .total-distance {
      font-size: 18px;
      font-weight: 700;
    }
    .total-price {
      color: #d0021b;
    }
    .mobile & .col_1,
    .col_2,
    .col_3 {
      font-size: 15px;
    }
  }

  .route-info__group {
    padding: 16px;
    // padding-left: 48px;

    .col_1 {
      font-size: 13px;
      font-weight: 400;
      color: #808080;
      margin-right: 15px;
      // padding-top: 5px;
    }
    .col_1:first-letter {
      text-transform: uppercase;
    }
    background-color: #f0fbff;
  }
  .route-info__plan {
    .col_1 {
      flex: 1.6;
    }
  }

  /* График */
  .chart-block {
    background: #fff;
  }
  .chart-block__title {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
    .mobile & {
      font-size: 18px;
    }
  }
  .chart-block__info {
    padding: 16px;

    .col_1 {
      flex: 1.6;
    }
    .mobile & {
      font-size: 15px;
    }
  }
  .chart-block__item {
    margin-bottom: 10px;
    .chart-block__name {
      float: left;
      color: #999;
      font-size: 13px;
      font-weight: 500;
      width: 55%;
    }
    .chart-block__value {
      float: left;
      font-size: 14px;
      text-align: left;
      font-weight: bold;
      width: 45%;
      .mobile & {
        font-size: 15px;
      }
    }
    &::last-child {
      margin-bottom: 0;
    }
  }

  .wrapper {
    .route-info__group {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  /* Легенда маршрута */
  .route-adminlevel {
    padding: 16px;
    background-color: #f0fbff;
    .wrap {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .route-adminlevel__item {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      &.type_23 {
        color: #00b53a;
      }
      &.type_32 {
        color: #f5a623;
      }
      &.type_98 {
        color: #436f85;
      }

      &.mobile {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .wrap .route-adminlevel__item.mobile {
      font-size: 18px;
    }
    b {
      color: #999;
    }
  }

  .mgo-accordion {
    .route-info__group {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .mgo-accordion__head {
    text-transform: uppercase;
  }
}
.route-info__item-rto.route-info__item {
  &-work {
    background-color: #00afec;
  }
  &-rest {
    background-color: #00b53a;
  }
  &-sleep {
    background-color: #f5a623;
  }
  .col_1,
  .col_2,
  .col_3 {
    color: white;
    font-weight: 700;
  }
  // margin: 0;
  margin-left: -16px;
  padding-left: 16px;
}

//для мобильной версии
.route-info.mobile {
  .mgo-accordion__head {
    font-size: 17px;
    font-weight: 700;
    line-height: 56px;
    background-color: #808080;
    .mgo-accordion__arrow {
      top: 25px;
    }
  }
  .mgo-accordion__item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }

  .mgo-accordion__head__with-accent {
    background-color: #00afec;
  }
  .route-puncts__content {
    .address {
      font-size: 17px;
    }
  }

  .route-info__group {
    .col_1,
    .col_2 {
      font-size: 15px;
    }
    .total-distance,
    .total-price {
      font-size: 20px;
    }
    .route-info__item.subtitle {
      font-size: 18px;
      margin-top: 16px;
    }
    .col_3 .label {
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      font-size: 16px;
      text-align: center;
      color: #fff;
      margin-right: 8px;
      font-weight: 700;
    }
    .col_3 {
      margin-right: 8px;
    }
  }
  .route-info__group.total {
    .col_1 {
      font-size: 17px;
    }
  }

  .mgo-punct-block {
    .name {
      font-size: 18px;
    }
  }
}

.route-info-navigator-button {
  display: flex;
  margin: auto;
  margin-top: 16px;
  svg {
    margin-left: 8px;
    transform: rotate(180deg);
    path {
      stroke: white;
    }
  }
}

.sprocess-route-wrapper {
  position: fixed;
  height: 100%;
  width: 380px;
  background-color: white;
  z-index: 1000;
}

.sprocess-route-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .button {
    width: 346px;
    margin-bottom: 16px;
  }
}
.sprocess-route-close {
  margin: 8px;
  margin-left: auto;
  border: 0px;
  background-color: white;
  color: #333333;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.sprocess-route-title {
  font-size: 18px;
  font-weight: 700;
  color: #00afec;
  margin-bottom: 16px;
}
