.mobile {
  .route-settings-row {
    width: calc(100% - 32px);
    .select-field {
      height: 48px;
      font-size: 18px;
    }
  }
  .select-field {
    width: 100%;
  }
  .tabs-primary__tab {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(33.33% - 3px);
  }
  .route-settings-name {
    font-weight: 700;
  }
  .chippicker__name {
    font-weight: 700;
  }
  .tabs-primary__tab {
    font-weight: 700;
  }

  .edit-point-autocomplete {
    .input-field {
      height: 42px;
      font-size: 17px;
    }
  }

  .route-points-list {
    // margin-bottom: 16px;
    .point {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 16px;
    }
    .reverse-icon {
      top: 38px;
    }
  }
  .autocomplete__li {
    font-size: 16px;
    line-height: 32px;
  }
  .route-build-status__name {
    font-size: 18px;
  }
  .route-build-status__value {
    font-size: 18px;
  }
  .control-panel-wrapper {
    width: 100%;
  }

  .save-send__name {
    font-size: 18px;
  }

  .route-buttons-panel {
    button {
      font-weight: 700;
    }
  }
}
